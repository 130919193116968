import styled from 'styled-components';

import vars from 'variables';

import download from 'assets/HODLRanks/Rank/download.svg';

const Root = styled.div`
  margin-top: 55px;
  text-align: center;

  a, strong {
    letter-spacing: 0.5px;
    line-height: 130%;
  }

  a, label {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }

  a, label, strong {
    display: block;
  }

  a {
    padding-right: 25px;
    background: url(${download}) no-repeat center right;
    background-size: 14px 14px;
  }

  a.image {
    margin-bottom: -6px;
    margin-right: 28px;
    text-indent: -9999px;
    background-size: 18px 18px;
  }

  strong {
    color: ${vars.colors.blue};
    display: block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      flex: 0 0 25%;
    }

    img {
      width: 150px;
    }

    a {
      padding-right: 20px;
      display: inline-block;
      font-size: 1.1rem;
    }

    strong {
      font-size: 1.025rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      flex: 0 0 50%;
    }

    img {
      width: 115px;
    }

    a, label, strong {
      font-size: 0.85rem;
    }
  }
`;

export { Root };
