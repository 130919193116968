import React from 'react';
import { Root } from './style';

import kebabCase from 'lodash/kebabCase';

const Rank = ({ name, image, threshold }) => {
  const filename = `${kebabCase(name)}.svg`;

  return (
    <Root>
      <a className="mobile image" href={image} target="_blank" rel="noreferrer" download={filename}>{name}</a>
      <img src={image}/>
      <div>
        <a className="desktop" href={image} target="_blank" rel="noreferrer" download={filename}>{name}</a>
        <label className="mobile">{name}</label>
        <strong>{threshold}</strong>
      </div>
    </Root>
  );
};

export default Rank;
