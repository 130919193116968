import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  .ranks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${vars.desktop.mediaQuery} {
    .ranks {
      margin-top: 50px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .ranks {
      margin-top: -10px;
    }

    .help-us {
      margin: 30px 0 50px 0;
    }

    .site-section {
      margin-bottom: 125px;
    }
  }
`;

export { Root };
